import { FC, MouseEvent } from 'react'
import { Grid } from '@mui/material'

import { AutocompleteOption, CommandParametersType } from '@types'

import { ParameterListItem } from '../ParameterListItem'

type ParameterListType = {
  objectCode: string
  commandParameterOptions: AutocompleteOption[]
  isLoadingCommandParameters: boolean
  list: CommandParametersType[]
  editItemIndex: number | null
  onShowScriptValueDialog: () => void
  onChangeType: (type: AutocompleteOption, index: number) => void
  onClear: (event: MouseEvent<HTMLButtonElement>, index: number) => void
  onDelete: (index: number) => void
  onEdit: (index: number | null) => void
  onUpdate: (index: number) => void
}

export const ParameterList: FC<ParameterListType> = ({
  objectCode,
  commandParameterOptions,
  isLoadingCommandParameters,
  editItemIndex,
  list = [],
  onShowScriptValueDialog,
  onChangeType,
  onClear,
  onDelete,
  onEdit,
  onUpdate,
}) => {
  if (!list.length) {
    return null
  }

  return (
    <Grid
      container
      sx={{
        maxHeight: 200,
        overflow: 'auto',
        border: theme => theme.palette.border.customTable,
      }}
    >
      {list.map((parameter, index: number) => (
        <ParameterListItem
          key={`parameter_${parameter.title}`}
          commandParameterOptions={commandParameterOptions}
          editItemIndex={editItemIndex}
          hasScroll={list.length >= 5}
          isLoadingCommandParameters={isLoadingCommandParameters}
          item={parameter}
          itemIndex={index}
          objectCode={objectCode}
          onChangeType={onChangeType}
          onClear={onClear}
          onDelete={onDelete}
          onEdit={onEdit}
          onSave={onUpdate}
          onShowScriptValueDialog={onShowScriptValueDialog}
        />
      ))}
    </Grid>
  )
}
