import { FC, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormGenerator, FormInput, ModalWrapper } from '@microservices/wiskey-react-components'
import { FormInputsType } from '@microservices/wiskey-react-components/dist/types'
import { Box, Grid } from '@mui/material'

import { VariableType } from '@pages/ContextMenuCreateOrEdit/ContextMenuCreateOrEdit'

import { CommandParameters } from '@components/CommandParameters'
import { ScriptValueDialog } from '@components/ScriptValueDialog'

import { useFetchAllViewQuery, useFetchCommandsByObjectCodeQuery } from '@redux/api'

import { checkValidJS, getEntityAutocompleteOptionsFromType } from '@helpers'
import {
  ENTITY_TYPES_REQUEST,
  GENERATOR_INPUT_TYPE,
  MAX_INPUT_LENGTH,
  MENU_POINT_TYPES,
  MODAL_TYPE,
  OBJECT_TYPES_FOR_MENU_POINT_LABEL,
  OBJECT_TYPES_FOR_MENU_POINT_VALUE,
} from '@constants'
import {
  AutocompleteOption,
  CommandParametersType,
  CommandType,
  TreeItemMenuPointOptionType,
} from '@types'

type AddMenuPointDialogProps = {
  objectCode: string | undefined
  treeNames: string[]
  item?: TreeItemMenuPointOptionType | null
  variables?: VariableType[]
  isLoadingObjects: boolean
  onSetConfigCommandInternalId: (value: string | null) => void
  onClose: () => void
  onSave: (data: AddMenuPointForm) => void
}

export type AddMenuPointForm = {
  title: string
  jsTitle: boolean
  titleJsValue: string
  menuPointType: AutocompleteOption | null
  objectType: AutocompleteOption<string> | null
  command: CommandType | null
  commandParameters: CommandParametersType[]
  value?: AutocompleteOption | null
  conditionsDisabled: string
  conditionsHidden: string
}

const OBJECT_TYPES_FOR_MENU_POINT_OPTIONS = [
  {
    id: OBJECT_TYPES_FOR_MENU_POINT_VALUE.GANTT_CHART,
    label: OBJECT_TYPES_FOR_MENU_POINT_LABEL.GANTT_CHART,
  },
  { id: OBJECT_TYPES_FOR_MENU_POINT_VALUE.VIEW, label: OBJECT_TYPES_FOR_MENU_POINT_LABEL.VIEW },
]

const MENU_POINT_TYPES_OPTIONS = [
  { id: MENU_POINT_TYPES.COMMAND, label: MENU_POINT_TYPES.COMMAND },
  { id: MENU_POINT_TYPES.LINK, label: MENU_POINT_TYPES.LINK },
  { id: MENU_POINT_TYPES.HEADER, label: MENU_POINT_TYPES.HEADER },
  { id: MENU_POINT_TYPES.DIVIDER, label: MENU_POINT_TYPES.DIVIDER },
  { id: MENU_POINT_TYPES.GROUP, label: MENU_POINT_TYPES.GROUP },
]

export const AddMenuPointDialog: FC<AddMenuPointDialogProps> = ({
  objectCode,
  item,
  treeNames,
  variables,
  isLoadingObjects,
  onSetConfigCommandInternalId,
  onClose,
  onSave,
}) => {
  const { t } = useTranslation()

  const [isShowScriptValueDialogTitle, setShowScriptValueDialogTitle] = useState(false)
  const [currentNameScriptDialog, setCurrentNameScriptDialog] = useState<
    'titleJsValue' | 'conditionsDisabled' | 'conditionsHidden' | null
  >(null)

  const methods = useForm<AddMenuPointForm>({
    defaultValues: {
      title: '',
      titleJsValue: '',
      jsTitle: false,
      menuPointType: null,
      objectType: null,
      value: null,
      commandParameters: [],
      command: null,
      conditionsDisabled: '',
      conditionsHidden: '',
    },
  })

  const {
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { isDirty },
  } = methods
  const watchedMenuPointType = watch('menuPointType')
  const watchJs = watch('jsTitle')
  const watchTitleJsValue = watch('titleJsValue')
  const watchObjectType = watch('objectType')
  const watchValue = watch('value')
  const watchCommandParameters = watch('commandParameters')

  const isLink = watchedMenuPointType?.id === MENU_POINT_TYPES.LINK
  const isDivider = watchedMenuPointType?.id === MENU_POINT_TYPES.DIVIDER
  const isHeader = watchedMenuPointType?.id === MENU_POINT_TYPES.HEADER
  const isGroup = watchedMenuPointType?.id === MENU_POINT_TYPES.GROUP
  const isCommand = watchedMenuPointType?.id === MENU_POINT_TYPES.COMMAND

  const isUiMenuType = isDivider || isHeader || isGroup

  const entityTypeForRequest = useMemo(() => {
    if (!watchObjectType?.id) {
      return undefined
    }

    switch (watchObjectType.id) {
      case OBJECT_TYPES_FOR_MENU_POINT_VALUE.GANTT_CHART:
        return ENTITY_TYPES_REQUEST.GANTT_CHART
      case OBJECT_TYPES_FOR_MENU_POINT_VALUE.VIEW:
        return ENTITY_TYPES_REQUEST.VIEW
      default:
        return undefined
    }
  }, [watchObjectType?.id])

  const {
    data: entities,
    isLoading: isLoadingEntities,
    isFetching: isFetchingEntities,
  } = useFetchAllViewQuery({ viewType: entityTypeForRequest }, { skip: !entityTypeForRequest })

  const loadingEntities = isLoadingEntities || isFetchingEntities

  const autocompleteValueOptions = useMemo(
    () =>
      getEntityAutocompleteOptionsFromType(watchObjectType?.id, {
        [OBJECT_TYPES_FOR_MENU_POINT_VALUE.VIEW]: entities?.data,
        [OBJECT_TYPES_FOR_MENU_POINT_VALUE.GANTT_CHART]: entities?.data,
      }),
    [watchObjectType?.id, entities?.data]
  )

  const { data: commands } = useFetchCommandsByObjectCodeQuery(objectCode, {
    skip: !objectCode,
  })

  const commandOptions: AutocompleteOption[] = useMemo(
    () => commands?.map(command => ({ id: command.code, label: command.code })) || [],
    [[commands]]
  )

  useEffect(() => {
    if (item) {
      reset({
        ...item.data,
        menuPointType: item.data.menuPointType
          ? { id: item.data.menuPointType, label: item.data.menuPointType }
          : null,
        objectType: item.data.objectType
          ? { id: item.data.objectType, label: item.data.objectType }
          : null,
        value: item.data.value ? { id: item.data.value, label: item.data.valueTitle } : null,
        commandParameters: item.data.commandParameters?.length
          ? item.data.commandParameters.map(param => {
              return {
                id: param.id,
                title: param.title ? { id: param.title, label: param.title } : null,
                type: param.type ? { id: param.type, label: param.type.toLowerCase() } : null,
                value: param.value,
                pathArray: param.pathArray,
              }
            })
          : [],
        command: item.data.command,
      })
    }

    if (item && item.data.menuPointType === MENU_POINT_TYPES.COMMAND) {
      onSetConfigCommandInternalId(item.data.value ?? null)
    }
  }, [item])

  useEffect(() => {
    if (isUiMenuType) {
      setValue('objectType', null, { shouldDirty: true })
      setValue('value', null, { shouldDirty: true })
    }
  }, [isUiMenuType])

  useEffect(() => {
    if (isDivider) {
      setValue('jsTitle', false, { shouldDirty: true })
    }
  }, [isDivider])

  const handleSave = (data: AddMenuPointForm) => {
    onSave(data)
  }

  const handleClose = () => {
    if (isDirty && !confirm(t('notifications.leave'))) {
      return
    }

    onClose()
  }

  const titleJsValue: FormInputsType = {
    name: 'titleJsValue',
    inputType: GENERATOR_INPUT_TYPE.TEXTAREA,
    placeholder: t('contextMenuCreate.addMenuPointModal.titleJsValue.placeholder'),
    label: t('contextMenuCreate.addMenuPointModal.titleJsValue.label'),
    maxLengthInput: MAX_INPUT_LENGTH,
    readOnly: true,
    labelSx: { minWidth: 150, width: 150 },
    rules: {
      required: true,
      maxLength: MAX_INPUT_LENGTH,
    },
    additionalBtn: {
      isEnabled: true,
      text: 'edit',
      color: 'primary',
      variant: 'contained',
      onClick: () => {
        setCurrentNameScriptDialog('titleJsValue')
        setShowScriptValueDialogTitle(true)
      },
    },
  }

  const handleCloseScriptDialog = () => {
    setCurrentNameScriptDialog(null)
    setShowScriptValueDialogTitle(false)
  }

  const handleSaveScriptDialog = (value: string) => {
    if (currentNameScriptDialog) {
      setValue(currentNameScriptDialog, value, { shouldDirty: true })
    }
  }

  const handleChangeMenuPointType = (value: AutocompleteOption) => {
    if (value && value.id === MENU_POINT_TYPES.COMMAND) {
      setValue('objectType', value as AutocompleteOption<string>)

      return
    }

    setValue('objectType', null, { shouldDirty: true })
  }

  const handleChangeCommandValue = (value: AutocompleteOption) => {
    if (value?.label) {
      const command = commands?.find(cmd => cmd.code === value.label)

      setValue('command', {
        id: null,
        name: command!.code,
        httpMethod: command!.httpMethod,
        objectCode,
      })
    }

    setValue('commandParameters', [])
  }

  const isCurrentTreeName = (value: string) =>
    Boolean(item?.data.title.toLowerCase() === value.toLowerCase())

  const isNameUsed = (value: string) =>
    !treeNames.includes(value.toLowerCase()) || isCurrentTreeName(value.toLowerCase())

  return (
    <ModalWrapper
      hideDivider
      containerStyle={{ px: 0.5, py: 0.5, borderRadius: 0 }}
      disabledSave={!isDirty}
      isShow={true}
      title={t('contextMenuCreate.addMenuPointModal.titleModal')}
      width={1200}
      onClose={handleClose}
      onSave={handleSubmit(handleSave)}
    >
      {isShowScriptValueDialogTitle && (
        <ScriptValueDialog
          windowBoundaries
          isShow={isShowScriptValueDialogTitle}
          modalType={MODAL_TYPE.EDIT}
          objectFields={[]}
          value={currentNameScriptDialog ? watch(currentNameScriptDialog) : ''}
          validator={value =>
            checkValidJS(
              value,
              { srcObj: {}, global: {}, local: {} },
              { local: variables?.map(({ field }) => field) || [], global: [] }
            )
          }
          onClose={handleCloseScriptDialog}
          onSave={handleSaveScriptDialog}
        />
      )}
      <Box pb={2} pt={2} px={2}>
        <FormProvider {...methods}>
          <FormGenerator
            inputs={[
              {
                name: 'rowTitle',
                inputs: [
                  {
                    name: 'title',
                    inputType: GENERATOR_INPUT_TYPE.INPUT,
                    label: t('contextMenuCreate.addMenuPointModal.title.label'),
                    placeholder: t('contextMenuCreate.addMenuPointModal.title.placeholder'),
                    rules: {
                      required: true,
                      validate: value => {
                        if (!isNameUsed(value) && value.trim().length !== 0) {
                          return t('contextMenuCreate.addMenuPointModal.title.errors.used', {
                            name: value,
                          })
                        }

                        if (value.trim().length === 0) {
                          return false
                        }
                      },
                    },
                    labelSx: { minWidth: 150 },
                  },
                  {
                    name: 'jsTitle',
                    inputType: GENERATOR_INPUT_TYPE.CHECKBOX,
                    label: t('contextMenuCreate.addMenuPointModal.jsTitle.label'),
                    labelPlacement: 'end',
                    disabled: isDivider,
                    labelSx: { minWidth: 30, width: 30 },
                  },
                ],
              },
              ...(watchJs ? [titleJsValue] : []),
            ]}
          />
          {/* поправить отступы, сделать универсально */}
          <Box mb={isCommand || isLink ? 1 : 3} mt={0}>
            <FormInput
              autocompleteOptions={MENU_POINT_TYPES_OPTIONS}
              inputType={GENERATOR_INPUT_TYPE.AUTOCOMPLETE}
              label={t('contextMenuCreate.addMenuPointModal.menuPointType.label')}
              labelSx={{ minWidth: 150 }}
              name={'menuPointType'}
              placeholder={t('contextMenuCreate.addMenuPointModal.menuPointType.placeholder')}
              rules={{ required: true }}
              onChangeAutocomplete={handleChangeMenuPointType}
            />
          </Box>
          {!isUiMenuType && (
            <Grid container>
              <FormGenerator
                mainGridSpacing={0.25}
                inputs={[
                  ...(isLink
                    ? [
                        {
                          name: 'objectType',
                          inputType: GENERATOR_INPUT_TYPE.AUTOCOMPLETE,
                          autocompleteOptions: OBJECT_TYPES_FOR_MENU_POINT_OPTIONS,
                          label: t('contextMenuCreate.addMenuPointModal.objectType.label'),
                          placeholder: t(
                            'contextMenuCreate.addMenuPointModal.objectType.placeholder'
                          ),
                          labelSx: { minWidth: 150 },
                          rules: {
                            required: isLink,
                          },
                          onChangeAutocomplete: () => {
                            setValue('value', null)
                          },
                        },
                        {
                          name: 'value',
                          inputType: GENERATOR_INPUT_TYPE.AUTOCOMPLETE,
                          loading: loadingEntities,
                          autocompleteOptions:
                            watchedMenuPointType?.id === MENU_POINT_TYPES.LINK
                              ? autocompleteValueOptions
                              : [],
                          label: t('contextMenuCreate.addMenuPointModal.value.label'),
                          placeholder: t('contextMenuCreate.addMenuPointModal.value.placeholder'),
                          labelSx: { minWidth: 150 },
                          formLabelSx: { pb: 2, pt: 0 },
                          rules: {
                            required: isLink,
                          },
                        },
                      ]
                    : []),
                  ...(isCommand
                    ? [
                        {
                          name: 'value',
                          inputType: GENERATOR_INPUT_TYPE.AUTOCOMPLETE,
                          loading: isLoadingObjects,
                          autocompleteOptions: commandOptions,
                          label: t('contextMenuCreate.addMenuPointModal.commandCode.label'),
                          placeholder: t(
                            'contextMenuCreate.addMenuPointModal.commandCode.placeholder'
                          ),
                          labelSx: { minWidth: 150 },
                          formLabelSx: { pb: 0, pt: 0 },
                          rules: {
                            required: isCommand,
                          },
                          onChangeAutocomplete: (value: AutocompleteOption) =>
                            handleChangeCommandValue(value),
                        },
                      ]
                    : []),
                ]}
              />
              {isCommand && (
                <CommandParameters
                  commandCode={watchValue?.label as string}
                  objectCode={objectCode as string}
                  parameters={watchCommandParameters}
                  onChange={fields => setValue('commandParameters', fields, { shouldDirty: true })}
                />
              )}
              <FormGenerator
                inputs={[
                  {
                    name: 'conditionsDisabled',
                    label: t('contextMenuCreate.addMenuPointModal.conditionsDisabled.label'),
                    placeholder: t(
                      'contextMenuCreate.addMenuPointModal.conditionsDisabled.placeholder'
                    ),
                    inputType: GENERATOR_INPUT_TYPE.TEXTAREA,
                    maxLengthInput: MAX_INPUT_LENGTH,
                    readOnly: true,
                    labelSx: { minWidth: 150 },
                    formLabelSx: { pr: 2 },
                    rules: {
                      maxLength: MAX_INPUT_LENGTH,
                    },
                    additionalBtn: {
                      isEnabled: true,
                      text: 'edit',
                      color: 'primary',
                      variant: 'contained',
                      onClick: () => {
                        setCurrentNameScriptDialog('conditionsDisabled')
                        setShowScriptValueDialogTitle(true)
                      },
                    },
                    rows: 1,
                    sx: {
                      '& .MuiInputBase-root': {
                        py: '8.5px',
                      },
                    },
                  },
                  {
                    name: 'conditionsHidden',
                    label: t('contextMenuCreate.addMenuPointModal.conditionsHidden.label'),
                    placeholder: t(
                      'contextMenuCreate.addMenuPointModal.conditionsHidden.placeholder'
                    ),
                    inputType: GENERATOR_INPUT_TYPE.TEXTAREA,
                    maxLengthInput: MAX_INPUT_LENGTH,
                    readOnly: true,
                    labelSx: { minWidth: 150 },
                    formLabelSx: { pr: 2 },
                    rules: {
                      maxLength: MAX_INPUT_LENGTH,
                    },
                    additionalBtn: {
                      isEnabled: true,
                      text: 'edit',
                      color: 'primary',
                      variant: 'contained',
                      onClick: () => {
                        setCurrentNameScriptDialog('conditionsHidden')
                        setShowScriptValueDialogTitle(true)
                      },
                    },
                    rows: 1,
                    sx: {
                      '& .MuiInputBase-root': {
                        py: '8.5px',
                      },
                    },
                  },
                ]}
                sx={{
                  '& .MuiGrid-item': {
                    '&:nth-last-child(-n+2)': {
                      pt: 0,
                    },
                    flexWrap: 'nowrap',
                  },
                }}
              />
            </Grid>
          )}
        </FormProvider>
      </Box>
    </ModalWrapper>
  )
}
