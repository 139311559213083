import i18next from 'i18next'
import last from 'lodash/last'
import * as yup from 'yup'

import { AutocompleteOption } from '@types'

import {
  ConfigField,
  EmbeddedObject,
  GANTT_BIND_TYPE_LIST,
  GanttActionType,
  GanttColumnForm,
  GanttFormValues,
  SectionType,
  TimelineItem,
} from '@gantt/components/GanttCreateOrEdit/types'
import { isCellTarget, isSegmentTarget, validateSegmentDates } from '@gantt/helpers'

import { ganttParametersFormSchema } from './ganttParametersForm'

const segmentDateField = yup.object().shape({
  field: yup.object().shape<Partial<Record<keyof ConfigField, yup.AnySchema>>>({
    pathStr: yup
      .string()
      .test('date', i18next.t('error.ganttConfiguration.segmentDatesError'), validateSegmentDates),
    pathArray: yup.array().of(
      yup.object().shape<Partial<Record<keyof EmbeddedObject, yup.AnySchema>>>({
        field: yup
          .string()
          .test('required', '', function (val, ctx) {
            const pathStr = ctx.from?.[1]?.value?.pathStr

            if (!pathStr) {
              return true
            }

            return Boolean(val?.trim())
          })
          .nullable(),
      })
    ),
  }),
})

const segmentLinkField = yup.object().shape<Partial<Record<keyof ConfigField, yup.AnySchema>>>({
  pathStr: yup
    .string()
    .test('required', '', val => Boolean(val?.trim()))
    .test('linkField', '', function (val, ctx) {
      const segmentItem = last(ctx?.from)?.value as unknown as SectionType

      const lastLinkX = last<EmbeddedObject>(segmentItem?.link?.axisX?.field?.pathArray)
      const lastLinkY = last<EmbeddedObject>(segmentItem?.link?.axisY?.field?.pathArray)

      const objectCodeX = lastLinkX?.objectCode
      const objectCodeY = lastLinkY?.objectCode
      const fieldX = lastLinkX?.field
      const fieldY = lastLinkY?.field

      const isErrorLinks = objectCodeX !== objectCodeY || fieldX !== fieldY

      if (!fieldX || !fieldY) {
        return true
      }

      return !isErrorLinks
    }),
  pathArray: yup.array().of(
    yup.object().shape<Partial<Record<keyof EmbeddedObject, yup.AnySchema>>>({
      field: yup
        .string()
        .test('required', '', val => Boolean(val))
        .nullable(),
    })
  ),
})

const requiredFieldPicker = yup.object().shape<Partial<Record<keyof ConfigField, yup.AnySchema>>>({
  pathStr: yup.string().test('required', '', val => Boolean(val?.trim())),
  pathArray: yup.array().of(
    yup.object().shape<Partial<Record<keyof EmbeddedObject, yup.AnySchema>>>({
      field: yup
        .string()
        .test('required', '', val => Boolean(val))
        .nullable(),
    })
  ),
})

const notRequiredFieldPicker = yup
  .object()
  .shape<Partial<Record<keyof ConfigField, yup.AnySchema>>>({
    pathArray: yup.array().of(
      yup.object().shape<Partial<Record<keyof EmbeddedObject, yup.AnySchema>>>({
        field: yup
          .string()
          .test('required', '', function (val, ctx) {
            const pathStr = ctx.from?.[1]?.value?.pathStr

            if (!pathStr) {
              return true
            }

            return Boolean(val?.trim())
          })
          .nullable(),
      })
    ),
  })

export const getGanttColumnFormSchema = (columnsList: GanttColumnForm[], isEdit: boolean) => {
  return yup.object().shape({
    code: yup
      .string()
      .test('required', '', val => Boolean(val?.trim()))
      .test('unique', i18next.t('error.duplicatedSystemName'), val => {
        const foundDuplicates = columnsList.filter(column => column.code === val)
        const isDuplicateError = foundDuplicates.length >= 1 && !isEdit

        return !isDuplicateError
      }),
    title: yup.string().test('required', '', val => Boolean(val?.trim())),
    field: yup.object().shape({
      field: requiredFieldPicker,
    }),
  })
}

export const ganttColumnFormSchema = yup
  .object()
  .shape<Partial<Record<keyof GanttColumnForm, yup.AnySchema>>>({
    code: yup.string().test('required', '', val => Boolean(val?.trim())),
    title: yup.string().test('required', '', val => Boolean(val?.trim())),
    field: yup.object().shape({
      field: requiredFieldPicker,
    }),
  })

export const ganttActionFormSchema = yup
  .object()
  .shape<Partial<Record<keyof GanttActionType, yup.AnySchema>>>({
    code: yup.string().test('required', '', val => Boolean(val?.trim())),
    actionCode: yup
      .string()
      .test('required', '', val => Boolean(val?.trim()))
      .nullable(),
    title: yup.string().test('required', '', val => Boolean(val?.trim())),
    eventCode: yup
      .string()
      .test('required', '', val => Boolean(val?.trim()))
      .nullable(),
    target: yup
      .string()
      .when('eventCode', ([eventCode], schema) => {
        if (isCellTarget(eventCode) || isSegmentTarget(eventCode)) {
          return schema.test('emptyField', '', val => Boolean(val?.trim()))
        }

        return schema
      })
      .nullable(),
    actionField: yup.object().shape({
      bindType: yup
        .string()
        // .test('required', '', val => Boolean(val?.trim()))
        .nullable(),
      form: yup
        .mixed()
        .when('bindType', ([bindType], schema) => {
          if (bindType === GANTT_BIND_TYPE_LIST.FORM) {
            return schema.test('emptyField', '', (val: AutocompleteOption) =>
              Boolean(val?.label?.trim())
            )
          }

          return schema
        })
        .nullable(),
      static: yup
        .string()
        .when('bindType', ([bindType], schema) => {
          if (bindType === GANTT_BIND_TYPE_LIST.STATIC) {
            return schema.test('emptyField', '', val => Boolean(val?.trim()))
          }

          return schema
        })
        .nullable(),
      json: yup
        .string()
        .when('bindType', ([bindType], schema) => {
          if (bindType === GANTT_BIND_TYPE_LIST.JSON) {
            return schema.test('emptyField', '', val => Boolean(val?.trim()))
          }

          return schema
        })
        .nullable(),
      js: yup
        .string()
        .when('bindType', ([bindType], schema) => {
          if (bindType === GANTT_BIND_TYPE_LIST.JS) {
            return schema.test('emptyField', '', val => Boolean(val?.trim()))
          }

          return schema
        })
        .nullable(),
      commands: yup.array().of(
        yup.object().shape({
          name: yup.object().shape({
            id: yup.string(),
          }),
        })
      ),
    }),
  })

export const resourceFormSchema = yup.object().shape({
  key: yup.object().shape({
    field: requiredFieldPicker,
  }),
  data: yup.object().shape({
    id: yup.string().test('required', '', val => Boolean(val?.trim())),
    label: yup.string().test('required', '', val => Boolean(val?.trim())),
  }),
  commands: yup.array().of(
    yup.object().shape({
      type: yup.object().shape({
        id: yup
          .string()
          .required()
          .test('required', '', val => Boolean(val?.trim())),
        label: yup
          .string()
          .required()
          .test('required', '', val => Boolean(val?.trim())),
      }),
      name: yup.object().shape({
        id: yup
          .string()
          .required()
          .test('required', '', val => Boolean(val?.trim())),
        label: yup
          .string()
          .required()
          .test('required', '', val => Boolean(val?.trim())),
      }),
    })
  ),
  columns: yup
    .array()
    .of(ganttColumnFormSchema)
    .min(1, i18next.t('error.ganttConfiguration.minColumnsError')),
  actions: yup.array().of(ganttActionFormSchema),
})

export const sectionFormSchema = yup
  .object()
  .shape<Partial<Record<keyof SectionType, yup.AnySchema>>>({
    link: yup.object().shape({
      axisY: yup.object().shape({
        field: segmentLinkField,
      }),
      axisX: yup.object().shape({
        field: segmentLinkField,
      }),
      systemName: yup.string().test('required', '', val => Boolean(val?.trim())),
    }),
    datetimeEnd: segmentDateField,
    datetimeStart: segmentDateField,
    duration: segmentDateField,
    title: yup.object().shape({
      field_array: yup.array().of(notRequiredFieldPicker),
      field: notRequiredFieldPicker,
    }),
    tooltip: yup.object().shape({
      field_array: yup.array().of(notRequiredFieldPicker),
      field: notRequiredFieldPicker,
    }),
  })

export const timelineFormSchema = yup
  .object()
  .shape<Partial<Record<keyof TimelineItem, yup.AnySchema>>>({
    key: yup.object().shape({
      field: requiredFieldPicker,
    }),
    data: yup.object().shape({
      id: yup.string().test('required', '', val => Boolean(val?.trim())),
      label: yup.string().test('required', '', val => Boolean(val?.trim())),
    }),
    commands: yup.array().of(
      yup.object().shape({
        type: yup.object().shape({
          id: yup
            .string()
            .required()
            .test('required', '', val => Boolean(val?.trim())),
          label: yup
            .string()
            .required()
            .test('required', '', val => Boolean(val?.trim())),
        }),
        name: yup.object().shape({
          id: yup
            .string()
            .required()
            .test('required', '', val => Boolean(val?.trim())),
          label: yup
            .string()
            .required()
            .test('required', '', val => Boolean(val?.trim())),
        }),
      })
    ),
    title: yup.object().shape({
      field_array: yup.array().of(notRequiredFieldPicker),
      field: notRequiredFieldPicker,
    }),
    tooltip: yup.object().shape({
      field_array: yup.array().of(notRequiredFieldPicker),
      field: notRequiredFieldPicker,
    }),
    sections: yup
      .array()
      .of(sectionFormSchema)
      .min(1, i18next.t('error.ganttConfiguration.minSegmentsError')),
  })

export const ganttConfigurationSchema = yup
  .object()
  .shape<Partial<Record<keyof GanttFormValues, yup.AnySchema>>>({
    code: yup.string().test('required', '', val => Boolean(val?.trim())),
    title: yup.string().test('required', '', val => Boolean(val?.trim())),
    canvas: ganttParametersFormSchema,
    resource: resourceFormSchema,
    timeline: yup
      .array()
      .of(timelineFormSchema)
      .min(1, i18next.t('error.ganttConfiguration.minBarsError')),
    sort: yup.object().shape({
      code: yup.string().test('required', '', val => Boolean(val?.trim())),
      value: yup.array().of(
        yup.object().shape({
          field: yup
            .object()
            .shape({
              pathStr: yup
                .string()
                .test(
                  'emptyOrder',
                  i18next.t('error.ganttConfiguration.defaultSortFillBothError'),
                  function (val, ctx) {
                    const [, sortRow] = ctx.from || []

                    return !(sortRow?.value.order && !val)
                  }
                )
                .nullable(),
              pathArray: yup.array().of(
                yup.object().shape<Partial<Record<keyof EmbeddedObject, yup.AnySchema>>>({
                  field: yup.string().test('required', '', val => Boolean(val)),
                })
              ),
            })
            .nullable(),
          order: yup
            .string()
            .when('field', ([field], schema) => {
              if (field?.pathStr) {
                return schema.test(
                  'emptyField',
                  i18next.t('error.ganttConfiguration.defaultSortFillBothError'),
                  val => Boolean(val?.trim())
                )
              }

              return schema
            })
            .nullable(),
        })
      ),
    }),
  })
