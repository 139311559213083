import moment from 'moment'

import { FIELD_BIND_TYPE_ENUM } from '@constants'
import { CommandParameterDTO } from '@types'

import { executeCode } from './executeCode'

export const getParamsFromMenuPointData = (
  commandParameters: CommandParameterDTO[],
  srcObj?: Record<string | number, unknown>
) => {
  return commandParameters.reduce((acc, item) => {
    switch (item.type) {
      case FIELD_BIND_TYPE_ENUM.JS: {
        acc[item.title] = executeCode(item.value, {
          srcObj: srcObj || {},
          moment,
        }) as string
        break
      }
      case FIELD_BIND_TYPE_ENUM.FIELD: {
        acc[item.title] = srcObj?.[item.value]
        break
      }
      case FIELD_BIND_TYPE_ENUM.STATIC: {
        acc[item.title] = item.value
        break
      }
      default:
        acc[item.title] = item.value
    }

    return acc
  }, {})
}
