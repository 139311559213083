import { FC, MouseEvent, useEffect, useMemo, useState } from 'react'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import isNumber from 'lodash/isNumber'
import { FormInput } from '@microservices/wiskey-react-components'
import { Check as CheckIcon, Clear as ClearIcon } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  SxProps,
  Theme,
  Typography,
} from '@mui/material'
import { GridExpandMoreIcon } from '@mui/x-data-grid'

import { EmbeddedObject } from '@pages/ConfiduredGantt/types'
import { ModalRefPathPicker } from '@pages/GanttChart/components/GanttCreateOrEdit/components/ModalRefPathPicker'

import { ScriptValueDialog } from '@components/ScriptValueDialog'

import { useFetchCommandParametersQuery } from '@redux/api/command.api'

import {
  FIELD_BIND_TYPE,
  FIELD_BIND_TYPE_ENUM,
  GENERATOR_INPUT_TYPE,
  MAX_INPUT_LENGTH,
  MODAL_TYPE,
} from '@constants'
import { AutocompleteOption, CommandParametersType } from '@types'

import { ParameterList } from './components/ParameterList'

type CommandParametersProps = {
  objectCode: string
  commandCode: string
  parameters: CommandParametersType[]
  onChange: (parameters: CommandParametersType[]) => void
  defaultExpanded?: boolean
  labelWidth?: number
  containerSx?: SxProps<Theme>
}

type CommandParametersForm = {
  title: AutocompleteOption | null
  type: AutocompleteOption | null
  value: string
  pathArray: EmbeddedObject | null
  parameters: CommandParametersType[]
}

export const COMMAND_PARAMETER_TYPES_OPTIONS = [
  { id: FIELD_BIND_TYPE_ENUM.FIELD, label: FIELD_BIND_TYPE.FIELD },
  { id: FIELD_BIND_TYPE_ENUM.STATIC, label: FIELD_BIND_TYPE.STATIC },
  { id: FIELD_BIND_TYPE_ENUM.JS, label: FIELD_BIND_TYPE.JS },
]

const defaultValues = {
  title: null,
  type: null,
  value: '',
  pathArray: null,
  parameters: [],
}

export const CommandParameters: FC<CommandParametersProps> = ({
  objectCode,
  commandCode,
  parameters,
  onChange,
  defaultExpanded = true,
  labelWidth = 150,
  containerSx = {},
}: CommandParametersProps) => {
  const { t } = useTranslation()

  const [isShowScriptValueDialog, setShowScriptValueDialog] = useState(false)
  const [editItemIndex, setEditItemIndex] = useState<number | null>(null)

  const methods = useForm<CommandParametersForm>({ defaultValues })
  const { control, watch, setValue, getValues, reset } = methods
  const { fields, append, update, remove } = useFieldArray({
    name: 'parameters',
    control,
  })
  const watchTitle = watch('title')
  const watchType = watch('type')
  const watchValue = watch('value')
  const watchPathArray = watch('pathArray')

  const { data: commandParameters, isLoading: isLoadingCommandParameters } =
    useFetchCommandParametersQuery(
      {
        objectCode,
        commandCode,
      },
      {
        skip: !objectCode || !commandCode,
      }
    )

  const commandParameterOptions: AutocompleteOption[] = useMemo(
    () => commandParameters?.params.map(param => ({ id: param.name, label: param.name })) || [],
    [[commandParameters]]
  )

  const handleSaveScriptDialog = (value: string) => {
    if (editItemIndex) {
      setValue(`parameters.${editItemIndex}.value`, value, { shouldDirty: true })

      return
    }

    setValue('value', value, { shouldDirty: true })
  }

  const handleCloseScriptDialog = () => {
    setShowScriptValueDialog(false)
  }

  const handleChangeType = (value: AutocompleteOption, index?: number) => {
    if (isNumber(index)) {
      setValue(`parameters.${index}.value`, '')
      setValue(`parameters.${index}.pathArray`, null)

      return
    }

    setValue('value', '')
    setValue('pathArray', null)
  }

  const handleClear = (event?: MouseEvent<HTMLButtonElement>, index?: number) => {
    if (isNumber(index)) {
      setValue(`parameters.${index}.title`, parameters[index].title)
      setValue(`parameters.${index}.type`, parameters[index].type)
      setValue(`parameters.${index}.value`, parameters[index].value)

      return
    }

    setValue('title', null)
    setValue('type', null)
    setValue('value', '')
  }

  const handleAdd = () => {
    append({
      id: null,
      title: watchTitle ?? null,
      type: watchType ?? null,
      value: watchValue,
      pathArray: watchPathArray,
    })
    handleClear()

    onChange(getValues().parameters)
  }

  const handleDelete = (index: number) => {
    remove(index)
    onChange(getValues().parameters)
  }

  const handleUpdate = (index: number) => {
    update(index, getValues().parameters[index])
    onChange(getValues().parameters)
  }

  useEffect(() => {
    reset({
      ...defaultValues,
      parameters,
    })
  }, [parameters])

  return (
    <Grid
      container
      sx={{
        mb: 3,
        ...containerSx,
      }}
    >
      {isShowScriptValueDialog && (
        <ScriptValueDialog
          windowBoundaries
          isShow={isShowScriptValueDialog}
          modalType={MODAL_TYPE.EDIT}
          objectFields={[]}
          title={t('tooltips.title.editJs')}
          value={watchValue}
          onClose={handleCloseScriptDialog}
          onSave={handleSaveScriptDialog}
        />
      )}
      <FormProvider {...methods}>
        <Grid item alignItems='center' flexDirection='row'>
          <Typography color='#616161' paddingY={1.5} variant='body1' width={labelWidth}>
            {t('contextMenuCreate.addMenuPointModal.parameters.label')}
          </Typography>
        </Grid>
        <Grid item width={`calc(100% - ${labelWidth}px)`}>
          <Accordion
            defaultExpanded={defaultExpanded}
            sx={{
              width: '100%',
              '&.MuiPaper-root': {
                borderRadius: 0,
                border: '1px solid #00000042',
                boxShadow: 'none',
              },
            }}
          >
            <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
              {t('commandParameters.title')}
            </AccordionSummary>
            <AccordionDetails sx={{ pt: 0, pr: 1 }}>
              <Grid container flexWrap='nowrap' xs={12}>
                <Grid item xs={3.5}>
                  <FormInput
                    autocompleteOptions={commandParameterOptions}
                    disableClearable={true}
                    inputType={GENERATOR_INPUT_TYPE.AUTOCOMPLETE}
                    label={t('commandParameters.inputs.parameter.label')}
                    labelSx={{ minWidth: 100, width: 100 }}
                    loading={isLoadingCommandParameters}
                    name='title'
                    placeholder={t('commandParameters.inputs.parameter.placeholder')}
                  />
                </Grid>
                <Grid item xs={2.5}>
                  <FormInput
                    autocompleteOptions={COMMAND_PARAMETER_TYPES_OPTIONS}
                    disableClearable={true}
                    inputType={GENERATOR_INPUT_TYPE.AUTOCOMPLETE}
                    label={t('commandParameters.inputs.type.label')}
                    labelSx={{ minWidth: 60, width: 70, ml: 1 }}
                    name='type'
                    placeholder={t('commandParameters.inputs.type.placeholder')}
                    onChangeAutocomplete={handleChangeType}
                  />
                </Grid>
                <Grid item xs={4.5}>
                  {watchType?.id !== FIELD_BIND_TYPE_ENUM.FIELD ? (
                    <FormInput
                      autocompleteOptions={COMMAND_PARAMETER_TYPES_OPTIONS}
                      disableClearable={true}
                      disabled={!watchType}
                      inputType={GENERATOR_INPUT_TYPE.INPUT}
                      label={t('commandParameters.inputs.value.label')}
                      labelSx={{ minWidth: 60, width: 70, ml: 1 }}
                      name='value'
                      placeholder={t('commandParameters.inputs.value.placeholder')}
                      readOnly={watchType?.id === FIELD_BIND_TYPE_ENUM.JS}
                      additionalBtn={{
                        isEnabled: watchType?.id === FIELD_BIND_TYPE_ENUM.JS,
                        text: 'edit',
                        color: 'primary',
                        variant: 'contained',
                        onClick: () => setShowScriptValueDialog(true),
                      }}
                      formInputContainerSx={{
                        flexWrap: 'nowrap',
                        '& .MuiButton-root': {
                          ml: 0.5,
                        },
                      }}
                      rules={{
                        maxLength: MAX_INPUT_LENGTH,
                      }}
                      onChangeAutocomplete={handleChangeType}
                    />
                  ) : (
                    <ModalRefPathPicker
                      hasClearInInput
                      embeddedObjectPickerControllerName='pathArray'
                      isDisabled={false}
                      label={t('commandParameters.inputs.value.label')}
                      name='value'
                      object={objectCode ? { id: objectCode, label: objectCode } : null}
                      pickerName='value'
                      sx={{
                        ml: 1,
                        '& .MuiTypography-root': {
                          width: 60,
                          minWidth: 60,
                        },
                        '& .MuiButton-root': {
                          margin: 0,
                          width: 64,
                        },
                      }}
                    />
                  )}
                </Grid>
                <Grid item xs={1.5}>
                  <IconButton
                    disabled={!watchTitle || !watchType || !watchValue}
                    size='medium'
                    sx={{ height: 40, ml: 1 }}
                    onClick={handleAdd}
                  >
                    <CheckIcon />
                  </IconButton>
                  <IconButton
                    color='error'
                    disabled={!watchTitle && !watchType && !watchValue}
                    size='medium'
                    sx={{ height: 40 }}
                    onClick={handleClear}
                  >
                    <ClearIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid container>
                <ParameterList
                  commandParameterOptions={commandParameterOptions}
                  editItemIndex={editItemIndex}
                  isLoadingCommandParameters={isLoadingCommandParameters}
                  list={parameters}
                  objectCode={objectCode}
                  onChangeType={handleChangeType}
                  onClear={handleClear}
                  onDelete={handleDelete}
                  onEdit={index => setEditItemIndex(index)}
                  onShowScriptValueDialog={() => setShowScriptValueDialog(true)}
                  onUpdate={handleUpdate}
                />
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </FormProvider>
    </Grid>
  )
}
