import { FC, MouseEvent, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import isNumber from 'lodash/isNumber'
import { FormInput } from '@microservices/wiskey-react-components'
import {
  Clear as ClearIcon,
  Delete as DeleteIcon,
  Done as DoneIcon,
  Edit as EditIcon,
} from '@mui/icons-material'
import { Box, Grid, IconButton } from '@mui/material'

import { ModalRefPathPicker } from '@pages/GanttChart/components/GanttCreateOrEdit/components/ModalRefPathPicker'

import { COMMAND_PARAMETER_TYPES_OPTIONS } from '@components/CommandParameters/CommandParameters'

import { FIELD_BIND_TYPE_ENUM, GENERATOR_INPUT_TYPE, MAX_INPUT_LENGTH } from '@constants'
import { AutocompleteOption, CommandParametersType } from '@types'

type ParameterListItemType = {
  item: CommandParametersType
  itemIndex: number
  objectCode: string
  commandParameterOptions: AutocompleteOption[]
  isLoadingCommandParameters: boolean
  hasScroll: boolean
  editItemIndex: number | null
  onShowScriptValueDialog: () => void
  onClear: (event: MouseEvent<HTMLButtonElement>, index: number) => void
  onChangeType: (type: AutocompleteOption, index: number) => void
  onDelete: (index: number) => void
  onEdit: (index: number | null) => void
  onSave: (index: number) => void
}

export const ParameterListItem: FC<ParameterListItemType> = ({
  item,
  itemIndex,
  objectCode,
  commandParameterOptions,
  isLoadingCommandParameters,
  hasScroll,
  editItemIndex,
  onShowScriptValueDialog,
  onChangeType,
  onClear,
  onDelete,
  onEdit,
  onSave,
}) => {
  const { t } = useTranslation()
  const { watch, handleSubmit } = useFormContext()
  const watchType = watch(`parameters.${itemIndex}.type`)

  const [isEdit, setEdit] = useState(false)

  const handleDelete = () => onDelete(itemIndex)
  const handleChangeType = (value: AutocompleteOption) => onChangeType(value, itemIndex)
  const handleEdit = () => {
    setEdit(true)
    onEdit(itemIndex)
  }
  const handleClear = (event: MouseEvent<HTMLButtonElement>) => {
    setEdit(false)
    onClear(event, itemIndex)
    onEdit(null)
  }
  const handleSave = () => {
    onSave(itemIndex)
    setEdit(false)
    onEdit(null)
  }

  return (
    <Grid
      container
      item
      alignItems='center'
      flexWrap='nowrap'
      sx={{
        position: 'relative',
        marginY: 0.25,
        height: 40,
      }}
    >
      {isEdit ? (
        <Grid container flexWrap='nowrap' height={40} xs={12}>
          <Grid item xs={3.5}>
            <FormInput
              autocompleteOptions={commandParameterOptions}
              disableClearable={true}
              formLabelSx={{ ml: '99px' }}
              inputType={GENERATOR_INPUT_TYPE.AUTOCOMPLETE}
              loading={isLoadingCommandParameters}
              name={`parameters.${itemIndex}.title`}
              placeholder={t('commandParameters.inputs.parameter.placeholder')}
              rules={{ required: true }}
              formInputContainerSx={{
                mb: 0,
              }}
            />
          </Grid>
          <Grid item xs={2.5}>
            <FormInput
              autocompleteOptions={COMMAND_PARAMETER_TYPES_OPTIONS}
              disableClearable={true}
              formLabelSx={{ ml: '68px' }}
              inputType={GENERATOR_INPUT_TYPE.AUTOCOMPLETE}
              name={`parameters.${itemIndex}.type`}
              placeholder={t('commandParameters.inputs.type.placeholder')}
              rules={{
                required: true,
                maxLength: MAX_INPUT_LENGTH,
              }}
              onChangeAutocomplete={handleChangeType}
            />
          </Grid>
          <Grid item xs={4.5}>
            {watchType?.id !== FIELD_BIND_TYPE_ENUM.FIELD ? (
              <FormInput
                autocompleteOptions={COMMAND_PARAMETER_TYPES_OPTIONS}
                disableClearable={true}
                disabled={!watchType?.id}
                formLabelSx={{ ml: '68px' }}
                inputType={GENERATOR_INPUT_TYPE.INPUT}
                name={`parameters.${itemIndex}.value`}
                placeholder={t('commandParameters.inputs.value.placeholder')}
                readOnly={watchType?.id === FIELD_BIND_TYPE_ENUM.JS}
                rules={{ required: true }}
                additionalBtn={{
                  isEnabled: watchType?.id === FIELD_BIND_TYPE_ENUM.JS,
                  text: 'edit',
                  color: 'primary',
                  variant: 'contained',
                  onClick: () => onShowScriptValueDialog(),
                }}
                formInputContainerSx={{
                  flexWrap: 'nowrap',
                  '& .MuiButton-root': {
                    ml: 0.5,
                  },
                }}
              />
            ) : (
              <ModalRefPathPicker
                hasClearInInput
                isRequired
                embeddedObjectPickerControllerName={`parameters.${itemIndex}.pathArray`}
                isDisabled={false}
                name={`parameters.${itemIndex}.value`}
                object={objectCode ? { id: objectCode, label: objectCode } : null}
                pickerName={`parameters.${itemIndex}.value`}
                sx={{
                  ml: 1,
                  '& .MuiTypography-root': {
                    width: 60,
                    minWidth: 60,
                  },
                  '& .MuiButton-root': {
                    margin: 0,
                    width: 64,
                  },
                }}
              />
            )}
          </Grid>
        </Grid>
      ) : (
        <Grid container>
          <Grid item pl='100px' width={287}>
            {item.title?.label ?? ''}
          </Grid>
          <Grid item ml={1} pl='60px' width={197}>
            {item.type?.label ?? ''}
          </Grid>
          <Grid item ml={1} pl='60px' width={361}>
            {item.value}
          </Grid>
        </Grid>
      )}

      <Box sx={{ position: 'absolute', right: hasScroll ? 20 : 33 }}>
        {isEdit ? (
          <Box>
            <IconButton
              disabled={isNumber(editItemIndex) && editItemIndex !== itemIndex}
              size='small'
              sx={{ width: 40 }}
              onClick={handleSubmit(handleSave)}
            >
              <DoneIcon fontSize={'small'} sx={{ height: 24 }} />
            </IconButton>
            <IconButton size={'small'} sx={{ width: 40 }} onClick={handleClear}>
              <ClearIcon />
            </IconButton>
          </Box>
        ) : (
          <Box>
            <IconButton
              disabled={isNumber(editItemIndex) && editItemIndex !== itemIndex}
              size='small'
              sx={{ width: 40 }}
              onClick={handleEdit}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              disabled={isNumber(editItemIndex) && editItemIndex !== itemIndex}
              size={'small'}
              sx={{ width: 40 }}
              onClick={handleDelete}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        )}
      </Box>
    </Grid>
  )
}
