import { URLS } from '@constants'
import { CommandTypeDTO } from '@types'

import { commonAPI } from './common.api'

export const commandAPI = commonAPI.injectEndpoints({
  endpoints: build => ({
    fetchCommandsByObjectCode: build.query<CommandTypeDTO[], string | void>({
      query: objectCode => ({
        url: `api/core/models/object/code/${objectCode}/commands`,
      }),
    }),
    fetchCommandParameters: build.query<
      CommandTypeDTO,
      { objectCode: string; commandCode: string }
    >({
      query: ({ objectCode, commandCode }) => ({
        url: `api/core/models/object/code/${objectCode}/commands/code/${commandCode}`,
      }),
    }),
    executeCommand: build.mutation<
      void,
      { commandId: string; body: string; params: Record<string, string | number> }
    >({
      query: ({ commandId, body, params }) => ({
        url: URLS.FRONT_API + `/command/${commandId}`,
        method: 'POST',
        params,
        body,
      }),
      extraOptions: {
        contentType: 'application/json',
      },
    }),
  }),
})

export const {
  useFetchCommandsByObjectCodeQuery,
  useFetchCommandParametersQuery,
  useExecuteCommandMutation,
} = commandAPI
